// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

// import '../../assets/stylesheets/application'
// import '../bootstrap_custom.js'

require("bootstrap")

window.$ = window.jQuery = require("jquery");

document.addEventListener("turbolinks:load", () => {

  $('[data-toggle="tooltip"]').tooltip()

  $("input.date-field").each(function() {

    if (this.type != 'date' ){

      $(this).datepicker(
        {
          dateFormat: 'yy/mm/dd',
          changeYear: true,
          changeMonth: true,
          minDate: "-110y",
          maxDate: "-14y"
        }
      )
    }
  })
})

